.border-top {
  border-top: 1px solid var(--color-gray-5); }

.padding-spacer {
  padding: 1rem 0 1rem 0; }

.flexbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
