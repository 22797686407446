.pagination-button-div {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.pagination-button-div button {
  cursor: pointer; }

.img-border {
  border: 1px solid #36363652; }

.white-background {
  background: white; }

.section-title {
  padding-top: 30px;
  padding-bottom: 30px; }

.bottom-border {
  border-bottom: 2px solid #f1f4f8; }

.bottom-border :hover {
  background: #f1f4f8;
  border-radius: .3rem; }

.post {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0; }

.post-min-height {
  min-height: 80px; }

.bottom-border:last-of-type {
  border-bottom-width: 0; }

.post-row {
  display: flex;
  align-items: center;
  padding: 0 1rem 0 1rem;
  width: 100%; }

time {
  color: #4a4a4a9c;
  font-size: .95rem;
  white-space: nowrap;
  font-weight: 400; }

@media screen and (min-width: 800px) {
  .flex-removal {
    display: block !important; } }

.post-row time {
  flex: 0 0 85px; }
  @media screen and (min-width: 768px) {
    .post-row time {
      flex: 0 0 94px; } }

.img-background {
  background: #d3d3d357; }

.sizing-fix {
  position: relative;
  height: 10rem; }

.image .news-img {
  max-width: 477px;
  width: 100%; }

.content-in-card {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left; }

.fit-width-mod {
  width: 100%; }
  @media screen and (max-width: 1087px) and (min-width: 769px) {
    .fit-width-mod {
      width: 95%; } }

@media screen and (max-width: 768px) {
  .extra-padding {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0;
    padding-bottom: 0; } }

.centered-flex-box {
  display: flex;
  justify-content: center; }

.hero-container {
  width: 100%;
  max-width: 925px;
  padding: 0 1.5rem;
  margin: 0 auto; }
  @media screen and (min-width: 800px) {
    .hero-container {
      padding: 0 2rem; } }

.news-title {
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: #363636;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.125; }

.news-title.invert {
  color: #ffffff; }

.invert {
  color: #ffffff !important; }

.news-section-title {
  font-size: 2rem;
  margin: 1rem 0 1rem;
  border-bottom: 4px solid #e4e4e4;
  border-bottom-width: 4px;
  padding-bottom: .5rem; }

.news-subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 3rem; }

.news-hero {
  background: white; }
