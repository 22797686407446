#stripes {
  width: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-8deg);
  transform: skewY(-8deg);
  box-shadow: inset 1px -3px 15px rgba(0, 0, 0, 0.35);
  background: linear-gradient(150deg, #53f 15%, #00a1ff 70%, #4196ff 94%);
  position: absolute; }
  #stripes span {
    position: absolute; }
