/* ///////////////////// TEST CATEGORY BUTTONS /////////////////////////////// */
.testchoice {
    /* width: 400px;
    height: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px; */
    font-size: 20px;
    text-align: center;
}

#subcarrier1 {
    /* width: 200px; */
    /* padding-top: 10px; */
    margin-right: 5px;
}

#subcarrier2 {
    /* width: 200px; */
    /* padding-top: 10px; */
    margin-left: 5px;
}

@media only screen and (max-width: 801px) {

    .testchoice{
        margin:10px;
        height: 40px;
        width: 300px;
    }

    .subcarrier {
        height: 40px;
        width: 150px;
        position: relative;
        margin-top: 0px;
        top: -10px;
        left: -80px;
        border-style: solid;
        margin:0px;
    }

    .tests-background-img {
        height: 300px;

    }

}
  
/* /////////////////////////// TEST CATEGORY BOXES /////////////////////////////////// */

#test-type {
    /* margin: 25px;
    padding-left: 50px;
    padding-right: 50px;
    border-style: solid;
    border-radius: 20px; */
    text-align: justify;
    font-size: 18px;
    
}

/* ///////////////////////////////TEST LINKS /////////////////////////////////////// */

.testlinks {
    /* margin: 10px;
    width: 23%;
    height: 50px;
    padding-top: 10px;
    font-size: 20px;
    position: relative;
    top: 0px; */
    margin-top: .5em;
    width: 100%;
    position: relative;
    margin-right: 1em;
}

#test-link-div {
    padding-top: 0px;
}

@media only screen and (max-width: 801px) { 
    .testlinks { 
        font-size: 18px; 
        width: 100%
    }
}

/* //////////////////////////// TEST BOXES AND COLUMNS ////////////////////////////////// */

.TestBox {
    /* margin: 25px;
    padding: 20px;
    border-style: solid;
    border-radius: 20px; */
    font-size: small;
}

.test-box-top{
    padding-top: 30px;
}

.testbox-expand {
    font-size: 18px;
    text-align: justify;
    padding: 20px;
}

.testbox-column-title {
    font-size: 30px;
}

/* ////////////////////TEST CHOICES IN CATEGORY BOXES /////////////////////////////// */

.test-box-button{
    margin:10px;
}

.test-box-buttons-div{
    text-align: center;
}

@media only screen and (max-width: 601px) { 
    .test-box-button {
        /* width: 100%;
        font-size: x-small; */
    }
}


/* //////////////////////////////// EXPANDABLES ///////////////////////// */

.expandable-button{
    position: relative;
    color: white;
    background-color: #276cda;
    border-radius: 5px;
    width: 150px;
    text-align: center;
    padding-bottom: 10px;
    font-size: 15px;
}

.second-expandable{
    margin-left: 1em;
}


@media only screen and (max-width: 360px) {
    .second-expandable {
        margin-left: 0px;
        margin-top:3px;
    }
}

@media only screen and (max-width: 526px) {
    .third-expandable {
        margin-top:3px;
    }
}
.material-icons {
    position: relative; 
    top: 7px;
    left: 15px;
}

.consent-icons {
    position: relative; 
    top: 7px;
    left: 6px;
}

@media only screen and (max-width: 400px) {
    .consent-icons {
        left: 4px;
    }
}

.chartimg {
    padding: 0em 17px 0 17px;
}

.expandable{
    /* border-radius: 10px;
    border-style: solid; */
    margin-top: 20px;
}

.sampleReqList {
    list-style-type: circle;
}

/* ////////////////////OUR TESTS BUTTON ON HOME PAGE ///////////////////////////// */

#small-test-button{
    /* width: 300px;
    height: 50px; */
    font-size: 20px;
    text-align: center;
    /* padding-top: 10px; */
}
