.contact-form {
  background-color: white;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.19); }

.fixed-icon {
  position: absolute;
  left: 0;
  top: 0; }

.table-row {
  display: table-row; }

.table-cell {
  display: table-cell; }

.table-cell-icon {
  padding: 0rem 1rem 0rem 0rem; }

.small-padding-top {
  padding-top: 1rem; }
