#shippingdiv {
    text-align: justify;
}

.shippingtitle {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.shippingsubtitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

@media only screen and (max-width: 799px) {
    .shippingbutton{
        max-width:100%;
        max-height:100%;
        margin:auto;
    }
}

@media only screen and (min-width: 800px) {
    .shippingbutton{
        max-width:33%;
        left:33%;
        margin:auto;
    }
}


