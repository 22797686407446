.scheduling-page {
  min-width: 320px; }

.iframe {
  height: 100%; }

.fill-it {
  width: 100%;
  height: 100%; }

.consulting {
  text-align: justify; }

@media only screen and (max-width: 600px) {
  .consulting {
    padding: 15px; }
  .min-height-iframe {
    height: 600px; } }

/* //////////////////////FOR IPADS, SMALL SCREENS///////////////// */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .consulting {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px; }
  .min-height-iframe {
    height: 600px; }
  #root {
    margin-bottom: 200px; } }

/* ////////////////////FOR SMALL SCREENS ///////////////////////////// */
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .consultation {
    position: relative;
    left: -500px; }
  .calendar-booking {
    position: relative;
    left: 520px; }
  .consulting {
    padding-right: 80px; } }

/* //////////////////////FOR BIG SCREENS ///////////////////////////////// */
@media only screen and (min-width: 1201px) {
  .consultation {
    position: relative;
    left: -650px;
    margin-top: 100px; }
  .calendar-booking {
    position: relative;
    left: 750px; }
  .consulting {
    padding-right: 300px;
    margin-bottom: 10px; } }
