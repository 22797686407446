.hero-body video {
  position: absolute;
  backface-visibility: hidden;
  right: 0;
  z-index: -10;
  object-fit: cover;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  max-width: none;
  max-height: none;
  height: -webkit-fill-available; }

.hero-body .container {
  max-width: 800px; }

.hero-body .title {
  color: #fcfdfd !important;
  text-align: left; }

.hero-body .subtitle {
  color: #fcfdfd !important;
  padding-top: 2rem;
  line-height: 1.5;
  text-align: left; }

.features {
  padding: 5rem 0; }

.services-padding-top {
  padding-top: 5rem; }

.box.cta {
  border-radius: 0;
  border-left: none;
  border-right: none; }

.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee; }

.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem; }

.card-content .content h4 {
  font-size: 16px;
  font-weight: 700; }

.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem; }

.intro {
  padding: 5rem 0;
  text-align: center; }

.hero-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 540px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.hero-section-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.new-hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1100px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: left;
  text-align: left; }

@media screen and (max-width: 1000px) {
  .hero-center-wrapper {
    max-width: 800px; } }

@media screen and (min-width: 1500px) {
  .hero-center-wrapper {
    max-width: 1500px; } }

@media screen and (min-width: 1088px) {
  .wide-screen-padding {
    padding-bottom: 5rem; } }

.hero-background-img {
  width: 100%;
  height: auto;
  background: url("../../assets/images/home-with-white.png") no-repeat center center;
  background-size: cover;
  background-position: center 136%; }

@media (min-width: 1149px) and (max-width: 1330px) {
  .hero-background-img {
    background-position: center 150%; } }

@media (min-width: 1140px) and (max-width: 1148px) {
  .hero-background-img {
    background-position: center 200%; } }

@media (min-width: 1109px) and (max-width: 1139px) {
  .hero-background-img {
    background-position: center 450%; } }

@media (min-width: 1097px) and (max-width: 1108px) {
  .hero-background-img {
    background-position: center -1439%; } }

@media (min-width: 1024px) and (max-width: 1096px) {
  .hero-background-img {
    background-position: center -239%; } }

@media (max-width: 1023px) {
  .hero-background-img {
    background-position: center 85%; } }

@media (min-width: 935px) and (max-width: 995px) {
  .hero-background-img {
    background-position: center 92%; } }

@media (min-width: 935px) and (max-width: 950px) {
  .hero-background-img {
    background-position: center 139%; } }

@media (max-width: 770px) {
  .hero-background-img {
    background-position: center 60%; } }

@media (max-width: 655px) {
  .hero-background-img {
    background-position: center 65%; } }

@media (max-width: 490px) {
  .hero-background-img {
    background-position: center 71%; } }

@media (max-width: 489px) {
  .hero-background-img {
    background-position: center 79%; } }

.background-img {
  width: 100%;
  background: url("../../assets/images/hero-background.png") no-repeat center center;
  background-size: cover; }

.tests-background-img {
  width: 100%;
  height: 350px;
  background: url("../../assets/images/testhero.png") no-repeat center center;
  background-size: cover; }

.no-vis {
  visibility: hidden; }

.w-background-video {
  position: relative;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.63);
  overflow: hidden;
  color: white; }

.w-background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  margin: auto;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  z-index: -100; }

.w-background-video > video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none; }

.sandbox {
  padding: 5rem 0; }

.siding {
  padding: 0px 31px 0px 31px; }

.siding-ys-and-left {
  padding-left: 31px;
  padding-top: 31px;
  padding-bottom: 31px; }

.tile.notification {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.is-shady {
  animation: flyintoright .4s backwards;
  background: #fff;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  display: inline-block;
  margin: 10px;
  position: relative;
  transition: all .2s ease-in-out; }

.is-shady:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.19); }

/*adds font awesome stars*/
footer li:before {
  content: '\f1b2';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: #147efb; }

section.hero {
  margin-bottom: 1rem; }

.fade-in {
  animation: fadeIn 3s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.hero-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem; }

@media (max-width: 1330px) {
  .hero-buttons-container {
    padding-bottom: 1rem; } }

.hero-button {
  flex: 1; }

.hero-button {
  flex: 1;
  text-align: left;
  text-decoration: none;
  color: inherit;
  transition: text-decoration 0.3s; }

.hero-button:hover {
  text-decoration: underline; }

@media (max-width: 768px) {
  .hero-buttons-container {
    flex-direction: column; }
  .hero-button {
    margin-bottom: 10px; } }

.chromosome-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 900px) {
  .chromosome-wrapper {
    display: none; } }

.chromosome-img {
  width: 200px;
  height: auto; }

.w-background {
  position: relative;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.63);
  overflow: hidden;
  background: white; }
