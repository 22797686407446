.navbar {
  background-color: #0f81cc;
  min-height: 5.25rem; }

.navbar-burger {
  margin: auto 0 auto auto;
  height: 5.25rem; }

.is-selected {
  color: #1ca64c; }

.is-above-all {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.11); }

.hero.is-info.is-bold {
  background-image: none; }

.hero.is-info {
  background-color: white;
  color: #4a4a4a; }

.button.get-brochures-button {
  background-color: #3e8ed0;
  border-color: transparent;
  color: #fff; }

.button.schedule-consult {
  background-color: #915bc6;
  border-color: transparent;
  color: #fff; }

.navbar-item img {
  max-height: 4.5rem; }

.navbar-item {
  margin: 0.25rem; }
  @media screen and (max-width: 350px) {
    .navbar-item {
      width: 16rem; } }
  @media screen and (max-width: 570px) and (min-width: 351px) {
    .navbar-item {
      width: 20rem; } }

.hero.is-info .navbar-menu {
  background-image: none;
  background-color: white;
  box-shadow: none; }

.hero.is-info .is-bold .navbar-menu {
  background-image: none; }

.hero.is-info .tabs a {
  color: #4a4a4a; }

.hero.is-info a.navbar-item:hover {
  background-color: white; }

.blue-strip-container {
  width: 100%;
  height: 26px;
  /* Adjust the height as needed */
  overflow: hidden; }

.blue-strip-navbar {
  width: 100%;
  height: auto; }
