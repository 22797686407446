#maps {
  width: 100%;
  height: 410rem;
  overflow: hidden;
  -webkit-transform: skewY(-8deg);
  transform: skewY(-8deg);
  margin-top: -10rem;
  box-shadow: inset 1px -3px 15px rgba(0, 0, 0, 0.35);
  background: white;
  background-image: url(../../assets/images/mapbackground3.png);
  position: absolute; }
  #maps span {
    position: absolute; }
  @media screen and (min-width: 300px) {
    #maps {
      height: 785rem; } }
  @media screen and (min-width: 515px) {
    #maps {
      height: 626rem; } }
  @media screen and (min-width: 720px) {
    #maps {
      height: 625rem; } }
  @media screen and (min-width: 769px) {
    #maps {
      height: 440rem; } }
  @media screen and (min-width: 865px) {
    #maps {
      height: 380rem; } }
  @media screen and (min-width: 910px) {
    #maps {
      height: 380rem; } }
  @media screen and (min-width: 1016px) {
    #maps {
      height: 395rem; } }
  @media screen and (min-width: 1088px) {
    #maps {
      height: 395rem; } }
  @media screen and (min-width: 1280px) {
    #maps {
      height: 320rem; } }

#maps-relative {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-8deg);
  transform: skewY(-8deg);
  margin-top: -11rem;
  box-shadow: inset 1px -3px 15px rgba(0, 0, 0, 0.35);
  background-image: url(../../assets/images/mapbackground3.png);
  z-index: -1;
  position: relative; }
  #maps-relative span {
    position: relative; }
  @media screen and (max-width: 768px) {
    #maps-relative {
      height: 255rem; } }
  @media screen and (min-width: 769px) {
    #maps-relative {
      height: 140rem; } }
  @media screen and (min-width: 865px) {
    #maps-relative {
      height: 170rem; } }
  @media screen and (min-width: 910px) {
    #maps-relative {
      height: 176rem; } }
  @media screen and (min-width: 961px) {
    #maps-relative {
      height: 180rem; } }
  @media screen and (min-width: 1016px) {
    #maps-relative {
      height: 188rem; } }
  @media screen and (min-width: 1088px) {
    #maps-relative {
      height: 194rem; } }
  @media screen and (min-width: 1280px) {
    #maps-relative {
      height: 200rem; } }
