.card {
  min-height: 100%;
  width: 100%; }

@media screen and (min-width: 768px) {
  .bottom-fixed {
    position: absolute;
    bottom: 2rem; } }

.card-content .content {
  margin-bottom: 3rem; }

.bumper-bottom {
  margin-bottom: 7rem; }

@media screen and (min-width: 1180px) {
  .container {
    max-width: 1152px; }
  .services-special {
    width: 1152px; } }

@media screen and (max-width: 668px) {
  .spacer-top {
    margin-top: 1rem; }
  .bottom-fixed {
    position: absolute; } }

.no-margin-bottom {
  margin-bottom: 0rem; }

.fade-out {
  transition: opacity 1s ease-out;
  opacity: 0; }

.fade-in {
  transition: opacity 1s ease-out;
  opacity: 1; }

.transition-height {
  transition: height 1s ease-out; }
