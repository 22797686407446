.inline {
  display: inline-block;
  text-align: center;
  padding-right: 1rem;
  padding-left: 1rem; }

.accredition-img {
  height: 7rem;
  padding-top: 1rem;
  margin-top: 1rem; }
  @media screen and (max-width: 932px) {
    .accredition-img {
      height: 6rem; } }
  @media screen and (max-width: 554px) {
    .accredition-img {
      height: 6rem; } }

.absolute {
  position: absolute; }

.accredition-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background: white; }

.logos-inline {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.simple-padding {
  padding-top: 2rem;
  padding-bottom: 4rem;
  max-width: 1158px;
  margin: 0 auto; }

.dch_special {
  padding-top: 1.5rem; }

.dch-img {
  width: 200px; }
  @media screen and (min-width: 1084px) {
    .dch-img {
      padding-bottom: 1.5rem; } }
  @media screen and (max-width: 1084px) {
    .dch-img {
      padding-top: 2.5rem; } }

@media screen and (max-width: 705px) {
  .fourth-acc {
    padding-top: 2.5rem; } }

@media screen and (max-width: 542px) {
  .third-acc {
    padding-top: 2.5rem; } }
