.intro-padding {
  padding-bottom: 7rem;
  max-width: 1158px;
  margin: 0 auto; }

.white-background {
  background: white; }

@media screen and (min-width: 1088px) {
  .wide-screen-padding {
    padding-bottom: 5rem; } }

.remove-padding-bottom {
  padding-bottom: 0rem; }
