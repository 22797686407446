.service-page-bg-img {
  background-image: url("../../stockphoto2.png");
  background-size: cover;
  max-width: 100%;
  height: auto;
  z-index: 1; }
  @media screen and (min-width: 1000px) {
    .service-page-bg-img {
      height: 400px;
      background-position: -9px right 0px; } }
  @media screen and (max-width: 769px) {
    .service-page-bg-img {
      height: 300px;
      background-position: -9px right 0px; } }

.payment-page-bg-img {
  background-image: url("../../assets/images/payment.png");
  background-size: cover;
  max-width: 100%;
  height: auto; }
  @media screen and (min-width: 1000px) {
    .payment-page-bg-img {
      height: 400px;
      background-position: -9px right 0px; } }
  @media screen and (max-width: 769px) {
    .payment-page-bg-img {
      height: 300px;
      background-position: -9px right 0px; } }

.background-text {
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125; }

.test-request-text {
  margin-left: 2%;
  margin-bottom: 2rem; }

.padding-link {
  padding: 1rem 0rem 1rem 0rem; }

.padding-top-2x {
  padding-top: 2rem; }

.is-hoverable-button {
  animation: flyintoright .4s backwards;
  background: #fff;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  display: inline-block;
  position: relative;
  transition: all .2s ease-in-out; }
