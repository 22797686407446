.scrollToTopBtn {
  position: fixed;
  left: 5%;
  bottom: 40px;
  height: 50px;
  font-size: 3rem;
  cursor: pointer;
  color: #628cd0;
  z-index: 100;
  opacity: 0;
  transform: translateY(100px);
  transition: all .5s ease; }

.shadow-lift-scroll-button {
  animation: flyintoright .4s backwards;
  background: #fff;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.19);
  border-radius: 37px; }

.shadow-lift-scroll-button:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.19);
  transform: scale(1.08);
  -webkit-transform: scale(1.08);
  opacity: 1; }

.showBtn {
  opacity: .7;
  transform: translateY(0); }
