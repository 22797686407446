.container.wider {
  margin: 0 auto; }

.add-padding-bottom {
  padding-bottom: 2rem; }

.add-padding-bottom2x {
  padding-bottom: 4rem; }

.add-padding-bottom5x {
  padding-bottom: 10rem; }

.add-padding-top {
  padding-top: 2rem; }

.hisat-img {
  width: 50%; }

.center-vertically {
  margin: auto 0; }

.is-primary {
  background-color: #0067d1; }

.bumper-top-tech {
  margin-top: 5rem; }
